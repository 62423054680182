//Used in Sample Test Cases, to be removed once we have enough test cases
export const LoanRepaymentMethods = {
  InterestOnly: "InterestOnly",
  PrincipleAndInterest: "PrincipleAndInterst",
};

export const Application = {
  Application: "application",
  Website: "website",
  StorageKeys: {
    MyOrganisations: "my-augmented-organisation-list",
    Navigation: {
      RedirectUrl: "redirect-url",
    },
  },
  Actions: {
    ManageSubscriptions: "manage-subscriptions",
    ManageBilling: "manage-billing",
  },
};

export const Product = {
  Names: {
    PellerexSoftwareFoundation: "pellerex-software-foundation",
    NotConfigured: "not-configured-api-endpoint",
    LocationApi: "location-api",
    ValidNameRegEx: /^[a-z0-9A-Z]{0,100}$/,
  },
  Category: {
    Foundation: "Foundation",
    API: "API",
    Service: "Service",
    ManagedApi: "ManagedApi",
  },
  Associations: {
    SubscriptionBelongsToAssociatedProduct:
      "SubscriptionBelongsToAssociatedProduct",
  },
  WellKnown: {
    ManagedApi: {
      NormalisedName: "managed-api-service",
      Actions: {
        ProvisionNewApi: "managed-api-service:provision-new",
      },
      FAQs: [
        {
          id: 1,
          question: "How long does it take for a new API to be up and running?",
          answer:
            "Within seconds! Anytime you provision a new API, a scaffolded repository, CI/CD pipelines and Azure KeyVaults for QA, Staging and Prod environments are also creatd, which means your API with a Hello World endpoint is operation on the Cloud within seconds.",
        },
        {
          id: 1,
          question: "What languages and stacks do you support?",
          answer:
            "All. Pellerex is a language agnostic platform. Right now we support .NET while Python and Node are coming soon. If you need any specific one, reach out and it will be supported within two weeks.",
        },
        {
          id: 2,
          question: "How many environments does each API have?",
          answer: "Three. QA, Staging, and Production.",
        },
        {
          id: 3,
          question:
            "Do deployments to higher environments need manual approval?",
          answer:
            "Yes, deployment to Staging and Production need manual approval.",
        },
        {
          id: 4,
          question:
            "Are my API endpoints protected? If yes, what is the authentication mechanism?",
          answer:
            "All APIs run on Pellerex are protected using OAuth and Json Web Token (JWT). When you want to consume your API, call our Identity endpoint API, retrieve a bearer token, and supply that in your API request.",
        },
        {
          id: 5,
          question: "What infrastructure do you use to run the APIs?",
          answer:
            "Kubernetes is the orchestrator use to run containerised API on the Cloud.",
        },
        {
          id: 6,
          question: "Do you provide secret management capability?",
          answer:
            "Yes, your API comes with nativ secret management capability provided by fully secure Cloud based KeyVaults. You can manage your secrets in your dashboard.",
        },
        {
          id: 7,
          question: "Do APIs have access to any databases?",
          answer:
            "Yes. As secret management is provided out of the box, you can connect with any Cloud based database of your choice. ",
        },
        {
          id: 8,
          question:
            "How do you manage configurations across different environments?",
          answer:
            "As code. Configurations in various environments are managed as code within your repository. Each environment has a separate configuration file.",
        },
        {
          id: 9,
          question:
            "What if I needed any of the above behaviour changed for my company?",
          answer:
            "Enterprise tier allows you to have our ecosystem customised. For example if you needed four environments instead of three, that's fully achievable when we run an instance of Pellerex, just for you!",
        },
      ],
    },
    ManagedIdentity: {
      NormalisedName: "managed-identity-service",
    },
    ManagedPayment: {
      NormalisedName: "managed-payment-service",
    },
  },
  Photo: {
    Type: {
      Banner: "Banner",
    },
  },
  Stack: [
    // {
    //     'key': 'Node',
    //     'text': 'Node',
    //     'value': 'Node'
    // },
    // {
    //     'key': 'Python',
    //     'text': 'Python',
    //     'value': 'Python'
    // },
    // {
    //   key: "Java",
    //   text: "Java",
    //   value: "Java",
    // },
    {
      key: "DotNET",
      text: ".NET",
      value: "DotNET",
    },
  ],
  Cloud: [
    // {
    //     'key': 'Node',
    //     'text': 'Node',
    //     'value': 'Node'
    // },
    // {
    //     'key': 'Python',
    //     'text': 'Python',
    //     'value': 'Python'
    // },
    {
      key: "Azure",
      text: "Azure",
      value: "Azure",
    },
  ],
  DeploymentMode: [
    {
      key: "Public",
      text: "Public",
      value: "Public",
      description:
        'Public mode enables you to host your AI model live behind our API gateway as well as creating a homepage for your model included in our public directory to advertise it or optionally monetise it later. You can then set your prices for your models, and sell subscription licenses to your customers. Please note, we only allow your "paid" customers to call your model via APIs, and no one else has access to your actual model files, which protects your IP rights.',
    },
    {
      key: "Private",
      text: "Private",
      value: "Private",
      description:
        "Private mode enables you to host your AI model live behind our API gateway that only you and your team can access. We also create a homepage for your models accessible only via the direct link you share with anyone. We will not showcase or include your model in our public AI directory.",
    },
  ],
  Pipelines: {
    Build: {
      Succeeded: "Succeeded",
      Canceled: "Canceled",
      Failed: "Failed",
    },
    Release: {
      Succeeded: "Succeeded",
      Canceled: "Canceled",
      Failed: "Failed",
    },
  },
  Reactions: {
    Liked: "Liked",
    Loved: "Loved",
    Inspired: "Inspired",
    Clapped: "Clapped",
  },
  Usage: {
    Api: {
      Succeeded: "Pellerex-Api-Call-Succeeded",
      Failed: "Pellerex-Api-Call-Failed",
      LimitReached: "Pellerex-Api-Call-LimitReached",
    },
  },
};

export const Auth = {
  SignIn: "Sign In",
  SignUp: "Sign Up",
  InvalidCredentials: "Email and/or password are incorrect.",
  PasswordRegEx: /^(?=.*[A-Z])(?=.*[\W])(?=.*[0-9])(?=.*[a-z]).{8,128}$/,
  PasswordFailMessage:
    "Passwords must have at least 8 characters, 1 lowercase, 1 upper case, 1 number, and 1 special character.",
  TokenHolderCookieName: "pellerex-external-auth",
  Audiences: {
    InternalEcosystemServiceProvider: "internal-ecosystem-service-provider",
    ManagedApi: "ecosystem-managed-api",
    ManagedServerlessApp: "ecosystem-managed-serverless-app",
    ManagedContainerApp: "ecosystem-managed-container-app",
  },
  Scopes: {
    viewProfile: {
      normalisedName: "view:profile",
      name: "View Profile",
      claims: {
        readProfile: "read-profile",
      },
    },
    viewEmail: {
      normalisedName: "view:email",
      name: "View Email",
      claims: {
        readEmail: "read-email",
      },
    },
    provisionAsset: {
      normalisedName: "provision:asset",
      name: "Provision Assets",
      claims: {
        provisionNewAsset: "provision-managed-api",
      },
    },
    viewSecretsInProd: {
      normalisedName: "view:secrets-prod",
      name: "View Secrets in Prod",
      claims: {
        readSecrets: "read-prod-secrets",
      },
    },
    manageSecretsInProd: {
      normalisedName: "manage:secrets-prod",
      name: "Manage Secrets in Prod",
      claims: {
        readSecrets: "read-prod-secrets",
        crudSecrets: "crud-prod-secrets",
      },
    },
    viewSecretsInNonProd: {
      normalisedName: "view:secrets-non-prod",
      name: "View Secrets in Non Prod",
      claims: {
        readSecrets: "read-non-prod-secrets",
      },
    },
    manageSecretsInNonProd: {
      normalisedName: "manage:secrets-non-prod",
      name: "Manage Secrets in Non Prod",
      claims: {
        readSecrets: "read-non-prod-secrets",
        crudSecrets: "crud-non-prod-secrets",
      },
    },
    readOrg: {
      normalisedName: "read:org",
      name: "Read Organisation",
      claims: {
        viewOrgBilling: "view-org-billing",
        viewOrgSubscriptions: "view-org-subscriptions",
      },
    },
    manageOrg: {
      normalisedName: "manage:org",
      name: "Manage Organisation",
      claims: {
        viewOrgBilling: "view-org-billing",
        crudOrgBilling: "crud-org-billing",
        viewOrgSubscriptions: "view-org-subscriptions",
        crudOrgSubscription: "crud-org-subscriptions",
      },
    },
    viewDevOpsNonProd: {
      normalisedName: "view:devops-non-prod",
      name: "View NonProd DevOps",
      claims: {
        viewNonProdEnvironments: "view-non-prod-envs",
      },
    },
    viewDevOpsProd: {
      normalisedName: "view:devops-prod",
      name: "View Prod DevOps",
      claims: {
        viewProdEnvironments: "view-prod-envs",
      },
    },
    manageDevOpsNonProd: {
      normalisedName: "manage:devops-non-prod",
      name: "Manage NonProd DevOps",
      claims: {
        viewNonProdEnvironments: "view-non-prod-envs",
        crudNonProdEnvironments: "crud-non-prod-envs",
      },
    },
    manageDevOpsProd: {
      normalisedName: "manage:devops-prod",
      name: "Managed Prod DevOps",
      claims: {
        viewProdEnvironments: "view-prod-envs",
        crudProdEnvironment: "crud-prod-envs",
      },
    },
    viewAppSpace: {
      normalisedName: "view:app-space",
      name: "View AppSpace",
      claims: {
        viewTenantOrgs: "view-app-space-settings",
      },
    },
    manageAppSpace: {
      normalisedName: "manage:app-space",
      name: "Manage AppSpace",
      claims: {
        viewAppSpaceSettings: "view-app-space-settings",
        manageAppSpaceSettings: "manage-app-space-settings",
      },
    },
    viewTenants: {
      normalisedName: "view:tenant",
      name: "View Tenant",
      claims: {
        viewTenantOrgs: "view-tenant-orgs",
        viewTenantUsers: "view-tenant-users",
        viewTenantRoles: "view-tenant-roles",
      },
    },
    manageTenants: {
      normalisedName: "manage:tenant",
      name: "Manage Tenant",
      claims: {
        viewTenantOrgs: "view-tenant-orgs",
        crudTenantOrgs: "crud-tenant-orgs",
        viewTenantUsers: "view-tenant-users",
        crudTenantOrgs: "crud-tenant-users",
        viewTenantRoles: "view-tenant-roles",
        crudTenantOrgs: "crud-tenant-roles",
      },
    },
    consumeLiveServices: {
      normalisedName: "consume:live-services",
      name: "Consume Live Services",
      claims: {
        callManagedApi: "call-managed-api",
      },
    },
  },
  Claims: {
    SessionId: "sid",
  },
};

export const Registration = {
  Planning: "ChoosePlan",
  Account: "Account",
  Summary: "Summary",
  Payment: "Payment",
  Confirmed: "Confirmed",
  Monthly: "Monthly",
  Yearly: "Yearly",
};

export const General = {
  PhoneValidationRegEx:
    /([0-9\s\-]{7,})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/,
  Validation: {
    RegExPatterns: {
      UUID: /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i,
      AlphaNumericPlusHyphenValidationRegEx: /^[a-zA-Z0-9-]+$/,
      AlphaNumericPlusDotValidationRegEx: /^[a-zA-Z0-9.]+$/,
      AlphaNumericValidationRegEx: /^[a-zA-Z0-9]+$/,
      AlphaValidationRegEx: /^[a-zA-Z]+$/,
      LowerCaseAlphaValidationRegEx: /^[a-z]+$/,
    },
  },
  DateTimeSettings: {
    Format: {
      DateFormat: "MM/DD/YYYY",
      TimeFormat: "HH:mm",
      DateTimeFormat: "MM/DD/YYYY HH:mm",
    },
    Component: {
      DateOnly: "DateOnly",
      TimeOnly: "TimeOnly",
      FullDateTime: "FullDateTime",
    },
    Intervals: {
      Last30Minutes: "Last30Minutes",
      Last60Minutes: "Last60Minutes",
      Last24Hours: "Last24Hours",
      Last2Days: "Last2Days",
    },
  },
  FormFactor: {
    Mobile: "Mobile",
    Tablet: "Tablet",
    Desktop: "Desktop",
  },
  LogLevel: {
    Trace: "Trace",
    Debug: "Debug",
    Information: "Information",
    Warning: "Warning",
    Error: "Error",
    Critical: "Critical",
    None: "None",
  },
  ErrorMessage:
    "Oops, we didn't expect this, please try again later, or send us an email at info@pellerex.com.",
  AlternativeErrorMessage:
    "Oops, we didn't expect this, please send us an email at info@pellerex.com",
  WillBeInTouch: "Got it, we will be in touch soon.",
  ErrorCodes: {
    SendEmailFailed: "SendEmailFailed",
    GeneralFailure: "Failed",
    Identity: {
      ExternalLoginFailedGeneralCode: "ExternalLoginFailedGeneral",
      ExternalLoginFailedGeneralMessage:
        "External login failed. Please try again or contact us.",
      LocalLoginFailedGeneralCode: "LocalLoginFailedGeneral",
      LocalLoginFailedGeneralMessage:
        "Login failed. Please try again or contact us.",
      InvalidCredentials: "InvalidCredentials",
      UserDisabledCode: "UserDisabled",
      UserDisabledMessage:
        "Your account has been blocked, please contact us for further assistance.",
      DuplicateUserCode: "DuplicateEmailHandle",
      DuplicateUserMessage:
        "Email has been taken, please choose a different one. Alternately, if this is your email address, please check other forms of logins (Google, Microsoft, Password Protected) as you may have used them to create your account.",
    },
  },
  MessageCopies: {
    Identity: {
      PasswordResetLinkMessageSent: `You would receive an email shortly if a password-protected account existed in our system. Alternatively you may have created your account with us through Google/Microsoft social logins.`,
    },
  },
  FeedbackStage: {
    PrePurchase: "PrePurchase",
    PostPurchase: "PostPurchase",
  },
  FeedbackEntityType: {
    Product: "Product",
  },
  Messages: {
    SaveCompleted: "Saved.",
    SaveFailed: "Save failed, try again please.",
  },
  ComponentCloseMode: {
    Event: "Event",
    Redirect: "Redirect",
  },
  MessageTypes: {
    Error: "Error",
    Success: "Success",
  },
  OperationResult: {
    Succeeded: "Succeeded",
    Failed: "Failed",
    Unknown: "Unknown",
    Canceled: "Canceled",
    Partial: "Partial",
  },
  OperationState: {
    Pending: "Pending",
    Completed: "Completed",
    Queued: "Queued",
    InProgress: "InProgress",
    Rejected: "Rejected",
  },
  FormStatus: {
    Loading: {
      Status: "loading",
      Message: "Loading...",
    },
    Loaded: {
      Status: "loaded",
      Message: "Finished.",
    },
  },
  GUID: "00000000-0000-0000-0000-000000000000",
  Environment: {
    Dev: {
      Code: "development",
      Name: "Development",
    },
    QualityAssurance: {
      Code: "qualityassurance",
      Name: "Quality Assurance",
    },
    Staging: {
      Code: "staging",
      Name: "Staging",
    },
    Production: {
      Code: "production",
      Name: "Production",
    },
  },
  Environments: [
    {
      key: "development",
      text: "Development",
      value: "development",
    },
    {
      key: "qualityassurance",
      text: "Quality Assurance",
      value: "qualityassurance",
    },
    {
      key: "staging",
      text: "Staging",
      value: "staging",
    },
    {
      key: "production",
      text: "Production",
      value: "production",
    },
  ],
  //To be replaced by the email in Contact Details
  PellerexEmailAddress: "info@pellerex.com",
  ContactDetails: {
    LinkedInPage: "https://www.linkedin.com/company/pellerex",
    TwitterHandle: "pellerex",
    Email: "info@pellerex.com",
    SupportEmailAddress: "support@pellerex.com",
  },
  ChatContext: {
    ChangeRequests: "ChangeRequests",
    DirectMessages: "DirectMessage",
  },
  AddressMode: {
    AddressLookup: "addressLookup",
    ManualEntry: "manualEntry",
  },
  ValueCollections: {
    YesNoUnsure: [
      {
        key: "yes",
        text: "Yes",
        value: "yes",
        description: "",
      },
      {
        key: "no",
        text: "No",
        value: "no",
        description: "",
      },
      {
        key: "unsure",
        text: "Unsure",
        value: "unsure",
        description: "",
      },
    ],
    FrontendStack: [
      {
        key: "react",
        text: "React",
        value: "react",
        description: "",
      },
      {
        key: "angular",
        text: "Angular",
        value: "angular",
        description: "",
      },
      {
        key: "vue",
        text: "Vue",
        value: "vue",
        description: "",
      },
      {
        key: "blazor",
        text: "Blazor",
        value: "blazor",
        description: "",
      },
    ],
    BackendStack: [
      {
        key: "php",
        text: "PHP",
        value: "php",
        description: "",
      },
      {
        key: ".net",
        text: ".NET",
        value: ".net",
        description: "",
      },
      {
        key: "python",
        text: "Python",
        value: "python",
        description: "",
      },
      {
        key: "java",
        text: "Java",
        value: "java",
        description: "",
      },
      {
        key: "django",
        text: "Django",
        value: "django",
        description: "",
      },
      {
        key: "nodejs",
        text: "Node.js",
        value: "nodejs",
        description: "",
      },
      {
        key: "laravel",
        text: "Laravel",
        value: "laravel",
        description: "",
      },
    ],
    DatabaseTool: [
      {
        key: "sqlserver",
        text: "Microsoft SQL Server",
        value: "sqlserver",
        description: "",
      },
      {
        key: "postgresql",
        text: "PostgreSQL",
        value: "postgresql",
        description: "",
      },
      {
        key: "mysql",
        text: "MySQL",
        value: "mysql",
        description: "",
      },
      {
        key: "mongodb",
        text: "MongoDB",
        value: "mongodb",
        description: "",
      },
      {
        key: "dynamodb",
        text: "DynamoDB",
        value: "dynamodb",
        description: "",
      },
      {
        key: "oracle",
        text: "Oracle",
        value: "oracle",
        description: "",
      },
      {
        key: "db2",
        text: "DB2",
        value: "db2",
        description: "",
      },
    ],
    HostingOptions: [
      {
        key: "app-services",
        text: "App Services",
        value: "app-services",
        description: "",
      },
      {
        key: "containerised",
        text: "Containerised",
        value: "containerised",
        description: "",
      },
      {
        key: "serverless",
        text: "Serverless",
        value: "serverless",
        description: "",
      },
    ],
    InfrastructureOptions: [
      {
        key: "aws",
        text: "AWS",
        value: "aws",
        description: "",
      },
      {
        key: "azure",
        text: "Azure",
        value: "azure",
        description: "",
      },
      {
        key: "gcp",
        text: "Google Cloud",
        value: "gcp",
        description: "",
      },
      {
        key: "oracle",
        text: "Oracle Cloud",
        value: "oracle",
        description: "",
      },
    ],
  },
  Notification: {
    Types: {
      Success: "success",
      Error: "error",
      Warning: "warning",
    },
  },
  StatusCodes: {
    OK: 200,
    Accepted: 202,
    InternalServerError: 500,
    NotFound: 404,
    BadRequest: 400,
  },
  DevOps: {
    Pipelines: {
      NoCodeChange: "No Code Change",
      Release: {
        Action: {
          Approved: "approved",
          Rejected: "rejected",
        },
      },
    },
  },
  ResourceTypes: {
    Database: {
      SQLServer: {
        ConnectionStringTemplate:
          "Server=tcp:pellerex.database.windows.net,1433;Initial Catalog={db-name};Persist Security Info=False;User ID={user-id};Password={password};MultipleActiveResultSets=True;Encrypt=True;TrustServerCertificate=False;Connection Timeout=30;",
      },
    },
  },
  Messaging: {
    Scope: {
      InterBrowser: "inter-browser",
    },
    Type: {
      AuthenticationSucceeded: "authentication-succeeded",
      AuthenticationFailed: "authentication-failed",
      ShouldRefreshThePage: "refresh-the-page",
    },
  },
};

export const SEO = {
  Types: {
    Article: "NewsArticle",
    Website: "Website",
  },
};

export const Content = {
  Types: {
    Foundation: "Foundation",
    Identity: "Identity",
    Publishing: "Publishing",
    Payment: "Payment",
    Messaging: "Messaging",
    Infrastructure: "Infrastructure",
    DevOps: "DevOps",
    ApiDevelopment: "ApiDevelopment",
    Announcement: "Announcement",
    PlatformEngineering: "PlatformEngineering",
    WebDevelopment: "WebDevelopment",
    MobileDevelopment: "MobileDevelopment",
    Marketplace: {
      AI: "AI-Marketplace",
    },
  },
};

export const Resources = {
  EngineeringRoadmap: "engineering-roadmap",
  Scoping: "scoping",
};

export const Licensing = {
  PaymentTypes: {
    Card: "card",
  },
  PaymentMode: {
    OneTime: "OneTime",
    oneTime: "oneTime",
    Recurring: "Recurring",
    Subscription: {
      Monthly: "monthly",
      Annually: "annualy",
    },
  },
  PaymentStatus: {
    RequiresAction: "RequiresAction",
    Succeeded: "Succeeded",
    RequiresPaymentMethod: "RequiresPaymentMethod",
    Failed: "Failed",
  },
  SubscriptionStatus: {
    RequiresAction: "RequiresAction",
    Succeeded: "Succeeded",
    RequiresPaymentMethod: "RequiresPaymentMethod",
    Failed: "Failed",
  },
};

export const Routes = {
  Marketplace: {
    Contributors: {
      Directory: "/contributors",
      Profile: "/c",
      Pricing: "/company/pricing",
    },
    Projects: {
      Directory: "/projects",
      PostNewProject: "/projects/new",
      Project: "/project",
    },
    Models: {
      Start: "/start",
      Directory: "/models",
      Templates: {
        Python: "https://dev.azure.com/Pellerex/public/_git/Python.API.SDK",
      },
    },
    Dashboard: {
      Home: "/dashboard",
      Organisation: "/dashboard/organisation",
      Apps: "/dashboard/apps",
      AppSpace: "/dashboard/app",
      Support: "/dashboard/support",
      Products: "/dashboard/products",
      Inbox: "/dashboard/inbox",
      Profile: "/dashboard/profile",
      BillingDetails: "/dashboard/billing-details",
    },
    Pages: {
      Home: "/",
      Landings: {
        Default: "/",
        Dashboard: "/dashboard",
        //This route will be retired by 1 March
        DataScientists1: "/build-ai-with-us",
        DataScientists: "/build",
        Monetisation: "/monetise",
      },

      Products: {
        Index: "/products",
        Foundation: {
          GetStarted: "/foundations/get-started",
          Base: "/foundation",
          List: "/foundations",
          Pellerex: "/pellerex",
          FoundationsInOnboardingMode: "/foundations?onboarding=true",
        },
        Service: {
          Base: "/service",
          List: {
            All: "/services",
            ManagedApiFoundation: {
              Submission: "/api/submit",
              Page: "/managed-api-service",
            },
          },
        },
        API: {
          Base: "/api",
          List: {
            All: "/apis",
            LocationApi: {
              Page: "/location-api",
            },
          },
        },
        Model: {
          Base: "/model",
          List: "/models",
        },
        APIs: "/apis",
      },
      Resources: {
        Base: "/resource?id={resourceId}",
        ScopingOutput: "/resource/scoping/output",
      },
      Blogs: {
        Directory: "/blog/list",
        ApiVersioning: "/blog/asp-net-core-web-api-versioning",
        WhatIsPellerex: "/blog/what-is-pellerex-foundation",
        URLRewrite: "/blog/url-rewrite-aspnet-core",
        Authentication:
          "/blog/react-redux-jwt-axios-asp-net-core-authentication-authorisation",
        AspNetCoreSetup: "/blog/asp-net-core-api-setup",
        ApiConfigManagement: "/blog/asp-net-5-web-api-configuration-management",
        ApiSecretManagement: "/blog/asp-net-5-web-api-secret-management",
        ApiMonitoring: "/blog/asp-net-5-web-api-monitoring",
        ApiDatabaseSetup:
          "/blog/database-setup-for-aspnet-5-web-api-with-entity-framework-and-sql",
        ApiDocumentation: "/blog/asp-net-5-api-documentation-with-swagger",
        ApiCors: "/blog/asp-net-5-cors",
        ApiFluentValidation:
          "/blog/asp-net-5-request-validation-with-fluent-validation",
        ApiExceptionHandling: "/blog/asp-net-5-global-exception-handling",
        AppSetup: "/blog/spa-with-asp-net-5-react-and-redux",
        AppThemeManagement: "/blog/spa-styling-and-theme-management-with-scss",
        AppGlobalNavigation: "/blog/spa-mobile-first-global-navigation",
        AppLayout: "/blog/spa-layout",
        AppForms: "/blog/spa-forms",
        AppRouting: "/blog/spa-routing",
        AppConfiguration: "/blog/spa-configuration",
        AppApiCall: "/blog/spa-api-call",
        AppMonitoring: "/blog/spa-monitoring",
        AppNotification: "/blog/spa-notification",
        AppRedux: "/blog/spa-react-redux",
        AppGoogleMaps: "/blog/spa-google-maps-polygons",
        AppSEO: "/blog/spa-react-seo",
        AppShellConfiguration: "/blog/spa-aspnet-react-configuration",
        AppAzureDeploy: "/blog/spa-deploy-aspnet-with-react-using-azure-devops",
        ApiIdentityDbSetup: "/blog/auth-identity-db-setup",
        ApiIdentityEmailSender: "/blog/auth-email-sender",
        IdentitySignUp: "/blog/identity-sign-up",
        IdentityGoogleAuth: "/blog/google-auth-for-react-with-aspnet-identity",
        IdentityMicrosoftAuth:
          "/blog/microsoft-auth-for-react-with-aspnet-identity",
        IdentityTwitterAuth:
          "/blog/twitter-auth-for-react-with-aspnet-identity",
        ApiCallOtherApis: "/blog/asp-net-core-api-call-other-apis",
        AppAuthStatusCheck: "/blog/identity-authenticated-hoc",
        IdentityChangePassword: "/blog/identity-change-password",
        IdentitySignIn: "/blog/identity-sign-in",
        IdentityClaimBasedAccessManagement:
          "/blog/claim-based-access-management",
        IdentityTokenRefresh: "/blog/identity-token-refresh",
        PaymentWithStripe: "/blog/payment-with-stripe",
        PaymentCaptureCardDetails:
          "/blog/capture-card-details-with-react-and-stripe-elements",
        PaymentOneTimeWithStripe: "/blog/process-one-time-payment-with-stripe",
        PaymentSubscriptionsWithStripe:
          "/blog/process-subscriptions-using-stripe",
        ApiLoggingWithSeriLog: "/blog/structured-logging-with-serilog",
        SoftwareRoadmap:
          "/blog/technologyleads-software-transformation-roadmap",
        TopLowCodePlatforms: "/blog/top-low-code-platform",
        ApiContainerisation: "/blog/containerise-aspnet-api",
        AppContainerisation:
          "/blog/containerise-react-app-with-aspnet-and-azure-devops",
        BoilerPlateSolutions: "/blog/aspnet-boiler-plate-solutions",
        DockerComposeMultiContainerApps:
          "/blog/docker-compose-for-multi-container-apps",
        MinikubeSetup: "/blog/run-aspnet-api-and-react-apps-on-minikube",
        KubernetesSetup: "/blog/kubernetes-with-aspnet-react-and-azure-devops",
        EventDrivenArchitecture:
          "/blog/event-driven-architecture-with-pellerex",
        CommandsCheatSheet: "/blog/commands-cheat-sheet",
        PerformanceAndCostOptimisation:
          "/blog/performance-and-cost-optimisation",
        ChatUsingSignalR: "/blog/realtime-chat-with-signalR-dotnet-k8s",
        AzureDevOpsRestAPIs: "/blog/foundation/azure-devops-rest-apis",
        AzureDevOpsCreateProject:
          "/blog/foundation/create-azure-devops-project",
        AzureDevOpsFetchProjects:
          "/blog/foundation/fetch-azure-devops-projects",
        AzureDevOpsInviteUsers:
          "/blog/foundation/invite-users-to-azure-devops-organisation",
        AzureDevOpsCreateSecurityGroup:
          "/blog/foundation/create-azure-devops-security-group",
        PellerexV2Release: "/blog/pellerex-v2-release",
        PellerexOnAzureMarketplace:
          "/blog/pellerex-products-on-azure-marketplace",
        DeployAIModels: "/blog/top-10-ways-to-deploy-ai-models-to-production",
        LoggingWithPellerex: "/blog/cloud-native-api-logging-with-pellerex",
        PellerexManagedApiService: "/blog/pellerex-managed-api-service",
        PellerexSecretManagement: "/blog/pellerex-secret-management",
        PlatformEngineering: "/blog/platform-engineering",
      },
      FAQs: {
        Base: "/faqs",
        ModelPerformanceReports: "/model-performance",
        CreatorRightsProtection: "/creator-right-protection",
        RunTimeArchitecture: "/runtime-architecture",
      },
      Terms: "/terms",
      Policies: "/policies",
      LogIn: "/auth",
      Conversion: {
        GettingStarted: "/getting-started",
        Licensing: {
          PaymentAuthorisation: "/registration/payment-authorisation",
          PaymentAuthorisationRedirect:
            "/registration/payment-authorisation-redirect",
          Confirmation: "/registration/confirmation",
        },
        //To be consolidated with talk to expert
        PlatformSupport: "/contact/support",
        ProductSupport:
          "/contact/support?appSpaceId={appSpaceId}&productId={productId}",
        Demo: "/demo",
        TalkToExpert: "/contact/expert",
        Acknowledge: "/contact/message/received",
        Types: {
          BlogSubscription: {
            Key: "NewsletterSubscription",
            Header: "Thanks. ",
            Message: "From now on, we will notify you whenever we publish.",
          },
          ContactUs: {
            Key: "TalkToUs",
            Header: "Thanks.",
            Message: "One of our team members will be in touch shortly.",
          },
        },
      },
    },
  },
  Foundation: {
    Root: "https://pellerex.com",
    Messaging: "https://pellerex.com/messaging",
    Identity: "https://pellerex.com/identity",
    Payment: "https://pellerex.com/payment",
    Kits: "https://pellerex.com/kits",
    Demo: "https://pellerex.com/demo",
  },
};

export const ApiDocumentation = {
  Language: "curl",
  Variant: "cURL",
  Theme: "javascript",
  Languages: [
    {
      language: "curl",
      variant: "cURL",
      theme: "javascript",
    },
    {
      language: "javascript",
      variant: "Fetch",
      theme: "javascript",
    },
    {
      language: "nodejs",
      variant: "request",
      theme: "javascript",
    },
    {
      language: "nodejs",
      variant: "Axios",
      theme: "javascript",
    },
    {
      language: "csharp",
      variant: "RestSharp",
      theme: "csharp",
    },
    {
      language: "php",
      variant: "cURL",
      theme: "php",
    },
    {
      language: "powershell",
      variant: "RestMethod",
      theme: "powershell",
    },
    {
      language: "python",
      variant: "http.client",
      theme: "python",
    },
    {
      language: "shell",
      variant: "wget",
      theme: "shellSession",
    },
    {
      language: "go",
      variant: "Native",
      theme: "go",
    },
    {
      language: "swift",
      variant: "URLSession",
      theme: "swift",
    },
    {
      language: "http",
      variant: "HTTP",
      theme: "javascript",
    },
    {
      language: "java",
      variant: "OkHttp",
      theme: "java",
    },
    {
      language: "javascript",
      variant: "jQuery",
      theme: "javascript",
    },
    {
      language: "javascript",
      variant: "XHR",
      theme: "javascript",
    },
  ],
};

export const Onboarding = {
  Steps: {
    InvitationProcessed: 4,
    UserAddedToGroup: 5,
    OrganisationDetailsHiddenFromUser: 6,
    KeyVaultCreated: 7,
    RepositoryInstalled: 8,
    BuildPipelineInstalled: 9,
    ReleasePipelineInstalled: 10,
    RepositoryPermissionsSet: 11,
    BuildPipelinePermissionsSet: 12,
    ReleasePipelinePermissionsSet: 13,
    RepositoryPoliciesSet: 14,
    ProxyRouteAdded: 15,
  },
  Repositories: {
    Purpose: {
      AIModel: "AI-Model",
    },
    //Corresponds to tech@pellerex.com account
    RequiredReviewerId: "c8ebfff9-f255-6492-81a8-a3f288235047",
  },
  PublicProfile: {
    Active: "Active",
    Inactive: "Inactive",
    NotExist: "NotExist",
  },
  Defaults: {
    DefaultOrganisationIdKeyName: "DefaultOrganisationId",
    DefaultAppSpaceIdKeyName: "DefaultAppSpaceId",
  },
};

export const Dashboard = {
  Commands: {
    ChangeApp: "change-app",
    ShowAppSpaceOverview: "show-app-space-overview",
    ShowServiceInstance: "general:show-service-instance",
    ShowApi: "show-api",
    ShowModel: "show-model",
    ShowInbox: "show-inbox",
    ShowProfile: "show-profile",
    ShowBillingDetails: "show-billing-details",
    ShowSubscriptions: "show-subscriptions",
    RefreshDashboard: "refresh-dashboard",
    ResetPassword: "reset-password",
  },
};
